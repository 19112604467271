<template>
    <v-app class="ma-n3">
        <v-container fluid>

            <v-row v-if="_isSafaricom" no-gutters>

                <v-col class="main-part d-lg-flex">

                    <div class="align-center mb-5">
                        <v-img src="saf-logo.png" width="180"></v-img>
                    </div>

                    <div class="text-center">

                        <h1 class="text-h5 font-weight-bold mb-4">
                            {{ _stepText(resetStep).title  }}
                        </h1>

                        <p class="text-body-2">
                            {{ _stepText(resetStep).message  }}
                        </p>

                    </div>

                    <v-card class="mt-3" width="500">

                        <v-card-text class="py-5">
                            <v-container>
                                <v-row v-if="resetStep == 1" no no-gutters class="flex-column">
                                    
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-col>
                                            <v-text-field v-model="email_address" label="Email Address" type="email"
                                                :rules="emailRules" required outlined dense
                                                placeholder="Enter email address..." persistent-placeholder
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-btn @click="validate" large block :loading="loading"
                                                :disabled="!valid || loading" color="primary">
                                                Send Code
                                            </v-btn>
                                        </v-col>

                                        <v-col>
                                            <a @click="redirectBackToLogin">Back To Login</a>
                                        </v-col>
                                    </v-form>
                                </v-row>

                                <v-row v-if="resetStep == 2" no no-gutters class="flex-column">
                                    
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-col v-if="resetStep == '1'">
                                            <v-text-field v-model="email_address" label="Email Address" type="email"
                                                :rules="emailRules" required outlined dense
                                                placeholder="Enter email address..." persistent-placeholder
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col v-if="resetStep == '2'">
                                            <v-otp-input v-model="code" :disabled="loading" @finish="validateToken"
                                                length="6"></v-otp-input>
                                        </v-col>
                                        <v-col>
                                            <v-btn @click="resendToken" large block :loading="loading"
                                                :disabled="!valid || loading" color="primary">
                                                Resend Code
                                            </v-btn>
                                        </v-col>

                                        <v-col>
                                            <a @click="redirectBackToLogin">Back To Login</a>
                                        </v-col>
                                    </v-form>
                                </v-row>

                                <v-row v-if="resetStep == 3" no no-gutters class="flex-column">
                                    
                                    <v-form ref="form" v-model="valid" lazy-validation>

                                        <v-col>
                                            <v-text-field outlined dense v-model="password"
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                                name="input-10-1" label="Enter new password" hint="At least 6 characters"
                                                counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>


                                        <v-col>
                                            <v-btn @click="updatePassword" large block :loading="loading"
                                                :disabled="!valid || loading" color="primary">
                                                Reset Password
                                            </v-btn>
                                        </v-col>

                                        <v-col>
                                            <a @click="redirectBackToLogin">Back To Login</a>
                                        </v-col>
                                    </v-form>
                                </v-row>
                            </v-container>
                        </v-card-text>

                    </v-card>
                </v-col>


            </v-row>

            <v-row v-if="!_isSafaricom" no-gutters>
                <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
                    <div class="d-flex">
                        <v-img v-bind:src="this.logo"></v-img>
                    </div>
                </v-col>
                <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
                    <v-row no-gutters class="align-start">
                        <v-col cols="12" class="login-part d-flex align-center justify-center bg-primary flex-column">
                            <div class="login-wrapper pt-md-4 pt-0">
                                <div class="d-flex d-lg-none mb-10">
                                    <v-img v-bind:src="this.logo"></v-img>
                                </div>
                                <v-container>
                                    <v-row v-if="resetStep == 1" no no-gutters class="flex-column">
                                        <v-col>
                                            <h1 class="text-h5 font-weight-bold mb-4">
                                                Forgot Your Password?
                                            </h1>
                                            <p class="text-body-2 mb-8">
                                                Please enter your email address to receive a verification code
                                            </p>
                                        </v-col>

                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-col>
                                                <v-text-field v-model="email_address" label="Email Address" type="email"
                                                    :rules="emailRules" required outlined dense
                                                    placeholder="Enter email address..." persistent-placeholder
                                                    hide-details="auto"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-btn @click="validate" large block :loading="loading"
                                                    :disabled="!valid || loading" color="primary">
                                                    Send Code
                                                </v-btn>
                                            </v-col>

                                            <v-col>
                                                <a @click="redirectBackToLogin">Back To Login</a>
                                            </v-col>
                                        </v-form>
                                    </v-row>

                                    <v-row v-if="resetStep == 2" no no-gutters class="flex-column">
                                        <v-col>
                                            <h1 class="text-h5 font-weight-bold mb-4">
                                                Check your email.
                                            </h1>
                                            <p class="text-body-2 mb-8">
                                                We have sent a password recovery code to your email
                                            </p>
                                        </v-col>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-col v-if="resetStep == '1'">
                                                <v-text-field v-model="email_address" label="Email Address" type="email"
                                                    :rules="emailRules" required outlined dense
                                                    placeholder="Enter email address..." persistent-placeholder
                                                    hide-details="auto"></v-text-field>
                                            </v-col>
                                            <v-col v-if="resetStep == '2'">
                                                <v-otp-input v-model="code" :disabled="loading" @finish="validateToken"
                                                    length="6"></v-otp-input>
                                            </v-col>
                                            <v-col>
                                                <v-btn @click="resendToken" large block :loading="loading"
                                                    :disabled="!valid || loading" color="primary">
                                                    Resend Code
                                                </v-btn>
                                            </v-col>

                                            <v-col>
                                                <a @click="redirectBackToLogin">Back To Login</a>
                                            </v-col>
                                        </v-form>
                                    </v-row>

                                    <v-row v-if="resetStep == 3" no no-gutters class="flex-column">
                                        <v-col>
                                            <h1 class="text-h5 font-weight-bold mb-4">
                                                Create new password.
                                            </h1>
                                            <p class="text-body-2 mb-8">
                                                Enter your new password below
                                            </p>
                                        </v-col>
                                        <v-form ref="form" v-model="valid" lazy-validation>

                                            <v-col>
                                                <v-text-field outlined dense v-model="password"
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                                    name="input-10-1" label="Enter new password"
                                                    hint="At least 6 characters" counter
                                                    @click:append="show1 = !show1"></v-text-field>
                                            </v-col>


                                            <v-col>
                                                <v-btn @click="updatePassword" large block :loading="loading"
                                                    :disabled="!valid || loading" color="primary">
                                                    Reset Password
                                                </v-btn>
                                            </v-col>

                                            <v-col>
                                                <a @click="redirectBackToLogin">Back To Login</a>
                                            </v-col>
                                        </v-form>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-footer>
                                <div class="primary--text text-caption">
                                    <a href="https://mediapal.net/" class="text-decoration-none">Tradedesk</a>, All rights
                                    reserved.
                                </div>
                            </v-footer>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
  
<script>
// import ErrorMessage from "../../components/ErrorMessage.vue";
import axios from 'axios';
export default {
    data: () => ({
        show1: false,
        email_address: "",
        password: "",
        code: "",
        valid: true,
        resetStep: 1,
        loading: false,
        emailRules: [
            value => {
                if (value) return true
                return 'E-mail is requred.'
            },
            value => {
                if (/.+@.+\..+/.test(value)) return true

                return 'E-mail must be valid.'
            },
        ],
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        credentials: {
            email_address: "",
            password: "",
        },
        params: {
            from: "",
            message: "",
        },
        error: null,
    }),
    components: {},
    computed: {
        // a computed getter
        logo() {
            if (location.hostname == "dsp.tag.co.ke") {
                return "https://live.mediapal.net/ads/logos/tradedesk/TAG.png";
            } else if (location.hostname == "desk.belvadigital.com") {
                return "https://live.mediapal.net/ads/logos/tradedesk/belva-dark.png";
            }
            return "https://live.mediapal.net/ads/logos/tradedesk/mediapal.png";
        },

        ___hostName() {
            const host = window.location.hostname;

            let url = '';

            if (host == 'https://test-tradedesk.mediapal.net' || host == 'localhost') {
                url = 'https://test-gateway.mediapal.net';
            } else if (host == 'https://tradedesk.mediapal.net' || host == "safaricom.mediapal.net") {
                url = 'https://gateway.mediapal.net';
            } else {
                url = 'https://gateway.mediapal.net';
            }

            return url;
        },

        _isSafaricom() {
            return location.hostname == "safaricom.mediapal.net" ? true : false;
        },
    },
    methods: {
        _stepText(step){
            if(step == 1){
                return {
                    title: "Forgot Your Password?",
                    message: "Please enter your email address to receive a verification code"
                }
            }

            if(step == 2){
                return {
                    title: "Check your email.",
                    message: "We have sent a password recovery code to your email"
                }
            }

            if(step == 3){
                return {
                    title: "Create new password.",
                    message: "Enter your new password below"
                }
            }

        },
        redirectBackToLogin() {
            this.$router.push({ name: "login" });
        },

        async validate() {

            this.valid = this.$refs.form.validate()

            if (this.valid) {
                this.sendToken();
            }
        },

        //send password reset code
        async sendToken() {
            let body = {
                email: this.email_address,
            };

            const url = this.___hostName

            //axios post to url/auth/reset-password with body and try catch
            try {
                this.loading = true;
                const response = await axios.post(`${url}/api/auth/password_link`, body);
                if (response.status == 200) {

                    this.resetStep = 2;
                    this.$notify({
                        title: 'We have sent a code to your email address.',
                        group: "success",
                    });
                }
            } catch (error) {

                this.$notify({
                    title: "No users found with that email address.",
                    group: "errors",
                });

            } finally {
                this.loading = false;
            }
        },

        async resendToken() {
            await this.sendToken()
        },

        //validate token - step 2
        async validateToken() {
            let body = {
                code: this.code,
            };

            const url = this.___hostName

            //axios post to url/auth/reset-password with body and try catch
            try {
                this.loading = true;
                const response = await axios.post(`${url}/api/auth/code/check`, body);
                if (response.status == 200) {

                    this.resetStep = 3;
                    this.$notify({
                        title: 'Your code is valid.',
                        group: "success",
                    });
                }
            } catch (error) {

                this.$notify({
                    title: error.response.data.message,
                    group: "errors",
                });

            } finally {
                this.loading = false;
            }
        },

        async updatePassword() {
            let body = {
                code: this.code,
                password: this.password,
                password_confirmation: this.password
            };

            const url = this.___hostName

            //axios post to url/auth/reset-password with body and try catch
            try {
                this.loading = true;
                const response = await axios.post(`${url}/api/auth/password/reset`, body);
                if (response.status == 200) {

                    this.$notify({
                        title: 'Your password has been updated successfully.',
                        group: "success",
                    });

                    //router push to login  page
                    this.$router.push({ name: "login" });

                    //set timeout to 10 seconds then set resetStep to 1
                    setTimeout(() => {
                        this.resetStep = 1;
                    }, 10000);
                }
            } catch (error) {

                this.$notify({
                    title: error.response.data.message,
                    group: "errors",
                });

            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 !important;

    .logo-sm {
        height: 23%;
    }

    .main-part {
        width: 100%;
        height: 100vh;
        background-color: #f6f7ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login-part {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        overflow-y: auto;

        .login-wrapper {
            width: 320px;
            height: auto;
        }

        .v-footer {
            position: absolute;
            bottom: 12px;
            background-color: #fff;
        }
    }
}
</style>
  
  